// used in the faq-1 block
export const accordionList1 = [
  [
    {
      no: 'One',
      expand: false,
      heading: 'Can I cancel my subscription?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    },
    {
      no: 'Two',
      expand: false,
      heading: 'Which payment methods do you accept?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    },
    {
      no: 'Three',
      expand: false,
      heading: 'How can I manage my Account?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    }
  ],
  [
    {
      no: 'Four',
      expand: false,
      heading: 'How do I get my subscription receipt?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    },
    {
      no: 'Five',
      expand: false,
      heading: 'Are there any discounts for people in need?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    },
    {
      no: 'Six',
      expand: false,
      heading: 'Do you offer a free trial edit?',
      body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
    }
  ]
];

// used in the faq-2 block
export const accordionList2 = [
  {
    no: 1,
    expand: false,
    heading: "Website health check",
    body: `We use advanced tools to perform a complete check of your website, looking at everything from page content to technical issues. We identify areas for improvement and recommend fixes to help your website grow and perform better in search results.`,
    body2: ""
  }, 
{
    no: 2,
    expand: false,
    heading: "Penalty removal",
    body: `If your site has been penalized by search engines (like Google), we can help you figure out why and get your rankings back on track. Our tools will spot any penalties and create a plan to recover your site’s visibility.`,
    body2: ""
  }, 
{
    no: 3,
    expand: false,
    heading: "Clickable content",
    body: `We create content designed to match what people are searching for online. Using data, we make sure the content attracts attention, builds links, and ranks well on search engines.`,
    body2: ""
  }, 
{
    no: 4,
    expand: false,
    heading: "User experience",
    body: `We review your site’s design and navigation, comparing it to best practices to make sure it’s easy for users to find what they need. Our goal is to improve the overall user experience and help turn visitors into customers.`,
    body2: ""
  }, 
{
    no: 5,
    expand: false,
    heading: "Website optimisation",
    body: `We analyze the technical side of your content management system (CMS) and recommend ways to improve it for better search engine results, whether you’re running a blog, news site, or online store.`,
    body2: ""
  },  
  {
      no: 6,
      expand: false,
      heading: "AI keyword research",
      body: `We use smart AI tools to research which keywords are most relevant to your business and how your site is performing. This helps us target the best keywords and fill in any gaps where competitors are outranking you.`,
      body2: ""
    },  
    {
        no: 7,
        expand: false,
        heading: "AI & Human Content",
        body: `Create and then edit AI content. You have to explain your services clearly and in an engaging manner to let Google know you mean business.`,
        body2: ""
      }, 
{
    no: 8,
    expand: false,
    heading: "Strong backlinks",
    body: `We help your site gain credibility by building high-quality backlinks. These are links from other websites that point to yours, boosting your authority in search engines and improving your chances of ranking higher.`,
    body2: ""
  }, 
{
    no: 9,
    expand: false,
    heading: "Website performance",
    body: `We ensure your website loads quickly and runs smoothly by analyzing and improving its performance. Fast load times improve your search rankings and create a better experience for users.`,
    body2: ""
  }, 
{
    no: 10,
    expand: false,
    heading: "Analytics & support",
    body: `We provide ongoing support with tools like Google Analytics to track your website’s performance and make adjustments based on real-time data. This helps us continually improve your site and maintain strong search rankings.`,
    body2: ""
  }


];

// used in the faq-2 block
export const accordionList99 = [
  {
    no: 1,
    expand: false,
    heading: 'Why do we need to conduct an SEO audit?',
    body: `To find out where your website is in terms of search engine optimisation, you should do an SEO audit. An SEO audit can boost your site's search engine rankings by analysing its current state and making recommendations for how to better optimise its content and structure.`,
    body2: ''
  },
  {
    no: 2,
    expand: false,
    heading: 'When should you schedule an SEO audit?',
    body: `An SEO audit's duration is highly sensitive to the site's size and complexity. A typical SEO audit conducted by Molto SEO takes between one and two weeks to complete.`,
    body2: ''
  },
  {
    no: 3,
    expand: false,
    heading: 'What information does an SEO audit include?',
    body: `Your website's current SEO state will be evaluated and recommendations for enhancements to the site's structure, content, keywords, backlink profile, and social media presence will be included in the SEO audit report. An implementation strategy for these suggestions is possible in the paper.`,
    body2: ''
  },
  {
    no: 4,
    expand: false,
    heading: 'Technical SEO Audit',
    body: 'Conduct an audit to identify quick wins and medium-term goals for content and site performance. Present strategy and reports to the client in one easy-to-read tailored document.',
    body2: ''
  },
  {
    no: 5,
    expand: false,
    heading: 'Priority Technical Error Fixing',
    body: `We prioritise fixing the most pressing technical issues including Google indexing, caching and page speed. All new sites are built to Accessibility standards and pass Lighthouse testing from the first commit to finish. `,
    body2: 'All current requirements are baked into our site code.'
  },
  {
    no: 6,
    expand: false,
    heading: 'AI enabled SEO keyword research',
    body: `We use the AI powered SEMRush API, Google AI, and Ahrefs to conduct the majority of our keyword research, alongside Google AI suggestions and AHREFS. We conduct keyword research by casting a wide net over your competitors and see how they are being found by web users.`,
    body2: ''
  },
  {
    no: 7,
    expand: false,
    heading: 'AI keyword grouping',
    body: 'Keywords are grouped by relevance and ease of success in ranking. These keywords and sub keywords are then used to create the site structure. This, as you can imagine, is a lot of data. Sorting is a huge task and in the hands of a human, messy and too time consuming.',
    body2:
      'Using a combination of AI and data sorting scripting, we are able to sift out unrelated keywords. This allows us to focus on the keywords that have relatively high search volume but a low amount of competing web pages: quick wins. Currently, the common practice is to group together 5 keywords per page, with one main keyword forming the page title and main heading. Here, AI helps by finding ingenious ways of combining keywords into super keyword phrases.'
  },
  {
    no: 8,
    expand: false,
    heading: 'AI/Manual content creation',
    body: `Create and edit AI content and/or provide keywords, site structure and template to client for content creation. Content generation - your website content is important for two reasons. The obvious reason is that you have to explain your services clearly and engagingly. `,
    body2: 'AI can help cut down this time by half, clearly structuring your ideas so you don\'t have to spend ages planning. It is suggested that clients produce YouTube content if possible. We improve our clients\' ROI by analysing website users, creating optimised pages. Optionally, we provide A/B testing to measure the real increase in sales.'
  },
  {
    no: 9,
    expand: false,
    heading: 'On-page and off-page linking strategy',
    body: `Develop a linking strategy based on desired sales conversion pages and make monthly recommendations for external links. Link weight is given to particular pages based on the client's desired sales conversion pages. We will make monthly recommendations for external links that will boost site visibility. We also offer an article placement service as well as approaching external sites for links. `,
    body2: 'Link building from other websites back to your own helps search engines determine how authoritative your website is and can help improve its ranking. Link building should be done carefully and with strategic planning to ensure that the links are from reputable websites that are related to the topic of your website. Using keywords in anchor text further improves rankings.'
  },
  {
    no: 10,
    expand: false,
    heading: 'Review, refine, repeat',
    body: `Reoptimise strategy in light of Google positions and conversion rates. We never cut corners. All issues are recorded and priority is placed on tasks that will have the most positive effect on sales and user experience. Update client on progress and make recommendations for the next phase via members dashboard.`,
    body2: 'Monitor / add funnels to Google Analytics.'
  }
];

// used in the faq-6 block
export const accordionList3 = [
  {
    no: 'One',
    expand: false,
    heading: 'How do I get my subscription receipt?',
    body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
  },
  {
    no: 'Two',
    expand: false,
    heading: 'Are there any discounts for people in need?',
    body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
  },
  {
    no: 'Three',
    expand: false,
    heading: 'Do you offer a free trial edit?',
    body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
  },
  {
    no: 'Four',
    expand: false,
    heading: 'How do I reset my Account password?',
    body: `Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.`
  }
];
