import { FC } from 'react';
import Accordion from 'components/reuseable/accordion';
// -------- data -------- //
import * as All from 'data/keywords';

// ============================================================
type Faq2Props = {
  title1: string;
  title2: string;
  fontsize1: number;
  fontsize2: number;
  subtitle: string;
  movie: any;
  placeholder: string;
  accordion: any;
  body: any;
  titleColor?: 'primary' | 'muted';
};
// ============================================================

const FAQ2: FC<Faq2Props> = ({
  title1,
  title2,
  subtitle,
  placeholder,
  movie,
  accordion,
  body,
  fontsize1,
  fontsize2,
  titleColor = 'primary'
}) => {
  return (
    <section id="point-process" className="video-hero wrapper angled lower-start  pt-15 pt-md-17">
      <h2 className="display-2 mt-3 mb-0 text-center" style={{ color: "#ccc", background: "none" }}>{title1} {title2}
        {/* <span className="hero_text">
          <p className={`fs-${fontsize1}`}>{title1} {title2}</p>
          <span className={`fs-${fontsize2}`}>{title2}</span>
        </span> */}


      </h2>
      <div className="row animate__animated animate__slideInUp animate__delay-2s">
        <div className=" col-12 mx-auto text-center mt-3">
          <h3 className="display-4 mb-5 text-capitalize" style={{ color: "#ccc" }}>
            for SEO Success<br></br>
            {/* <span style={{ fontSize: 20 }}>- AI driven SEO Services -</span> */}
          </h3>
        </div>
      </div>
      <div className="row animate__animated animate__slideInUp animate__delay-2s">
        <div className="col-10 col-md-11 mx-auto text-center mb-10" style={{ color: "#ccc" }} >
          <p>
            With our unique, data-driven SEO process, we don't just improve your website's rankings — we drive more traffic, boost visibility, and ultimately convert that traffic into sales. </p>
          {/* <p>Our tailored approach ensures your site attracts the right visitors, stays ahead of the competition, and delivers results that directly impact your bottom line.
          </p> */}
        </div>
      </div>
      <div className="row w-100 mb-md-20">
        <div className="mx-auto col-sm-10 col-md-8 col-lg-6 col-xl-5">
          <ol className="animate__animated animate__slideInUp animate__delay-3s accordion-wrapper w-100 p-0" id="accordion" >
            {accordion.map((item: any) => (
              <Accordion key={item.no} {...item} />
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default FAQ2;
